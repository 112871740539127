<!--[if lt IE 7]>
            <p class="browsehappy">You are using an <strong>outdated</strong> browser. Please <a href="http://browsehappy.com/">upgrade your browser</a> to improve your experience.</p>
        <![endif]-->
<div class="header" id="top">

    <nav class="navbar navbar-inverse" role="navigation">
        <div class="container">
            <div class="navbar-header">
                <button type="button" id="nav-toggle" class="navbar-toggle" data-toggle="collapse"
                    aria-controls="main-nav" data-target="#main-nav" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <a href="#" class="navbar-brand scroll-top"><img width="150" height="50" src="../assets/images/ho2propertieslogo1.png"></a>
            </div>
            <!--/.navbar-header-->
            <div id="main-nav" class="collapse navbar-collapse">
                <ul class="nav navbar-nav navbar-right">
                    <li><a href="#top" class="active">Home</a></li>
                    <li><a href="#about-us" class="about_trigger">About Us</a></li>
                    <!-- <li><a href="#news" class="news_trigger">News</a></li> -->
                    <li><a href="#strategy" class="portfolio_trigger">Our Strategy</a></li>
                    <li><a href="#contact" class="contact_trigger">Contact</a></li>
                    <!-- <li><a href="#blog" class="blog_trigger">Blog</a></li> -->
                    <!--/.<li><a href="#">Menu</a>
                                  <ul class="sub-menu">
                                    <li><a href="#">Submenu 1</a></li>
                                    <li><a href="#">Submenu 2</a></li>
                                    <li><a href="#">Submenu 3</a></li>
                                  </ul>
                                </li>-->
                </ul>
            </div>
            <!--/.navbar-collapse-->
        </div>
        <!--/.container-->
    </nav>
    <!--/.navbar-->
</div>
<!--/.header-->
<div class="parallax">
    <div class="bg__first templatemo-position-relative">
        <div class="container">
            <div class="templatemo-flexbox">
                <div class="row">
                    <div class="col-xs-12 text-center">
                        <div class="right-caption wow animated fadeInLeft">
                            <h1 class="banner-h1">Investing in DFW Real Estate Market</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="arrow text-center wow animated slideInDown">
            <a href="#" class="scroll-link btn" data-id="about-us"><i class="fa fa-angle-down"></i></a>
        </div>
    </div>

    <div id="about-us">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="single-item">
                        <h2 class="font-h2">RESIDENTIAL &</h2>
                        <h2 class="font-h2 font-h2-bottom">COMMERCIAL PROPERTIES</h2>
                        <div class="row">
                            <div class="col-md-3">
                                &nbsp;
                            </div>
                            <div class="col-md-6 col-xs-12">
                                <p>We specialize in investing in residential and commercial properties for various
                                    business initiatives.
                                </p>
                            </div>
                            <div class="col-md-3">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section-fearless">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 wow animated fadeInLeft">
                    <p>STRATEGIC AND BOLD INVESTMENTS IN REAL ESTATE OPPORTUNITIES</p>
                </div>
            </div>
        </div>
    </div>
    <div class="section-green-border">
        &nbsp;
    </div>
    <div class="section-background-four">
        <div class="bg__fourth">
        </div>
    </div>

    <div class="section-roland-vc" id="strategy">
        <div class="container">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h2 class="font-h2">WHY HO2 PROPERTIES?</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10 col-md-offset-1 col-xs-12">
                    <div class="sevice-items">
                        <div class="col-md-4 col-sm-6">
                            <div class="single-item">
                                <h5>MULTIPLE EXIT STRATEGIES</h5>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="single-item">
                                <h5>SINGLE FAMILY PROPERTY REHAB</h5>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="single-item">
                                <h5>MULTI-FAMILY APARTMENTS</h5>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="single-item">
                                <h5>COMMERCIAL PROPERTY LEASING</h5>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="single-item">
                                <h5>PROPERTY MANAGEMENT</h5>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6">
                            <div class="single-item">
                                <h5>FOCUS ON DFW MARKET</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section-recent-news" id="news">
        <div class="container">
            <!-- <div class="row">
                <div class="col-md-12 text-center">
                    <h2 class="font-h2">RECENT NEWS</h2>
                </div>
            </div> -->
            <!-- <div class="row hidden-xs visible-sm visible-md visible-lg">
                <div class="col-md-9 col-md-offset-1 col-sm-12 col-xs-12">
                    <div class="sevice-items">
                        <div class="col-sm-6 col-xs-12">
                            <div class="single-item  wow animated fadeInLeft">
                                <h6 class="font-h6">PRESIDENT OF WORLD BANK</h6>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <div class="single-item  wow animated fadeInLeft">
                                <h6 class="font-h6">HLG ANNOUNCES THE PROPOSED</h6>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <div class="single-item  wow animated fadeInLeft">
                                <h6 class="font-h6">GROUP RELEASES A SATATEMENT </h6>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <div class="single-item  wow animated fadeInLeft">
                                <h6 class="font-h6">SALE OF RANDALL TO KANT & RIDER</h6>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <div class="single-item-date">
                                <h6 class="font-h6">MAY 16, 2023</h6>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-12">
                            <div class="single-item-date">
                                <h6 class="font-h6">JULY 7, 2023</h6>
                            </div>
                        </div>
                        <div class="col-sm-6 col-xs-12 see-more">
                            <button class="btn btn-light">See More News</button>
                        </div>
                    </div>
                </div>
            </div> -->

            <!--Show for Extra small onle-->
            <!-- <div class="row hidden-sm hidden-md hidden-lg visible-xs">
                <div class="col-md-9 col-md-offset-1 col-sm-12 col-xs-12">
                    <div class="sevice-items">
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="single-item  wow animated fadeInLeft">
                                    <h6 class="font-h6">PRESIDENT OF WORLD BANK</h6>
                                </div>
                            </div>
                            <div class="col-xs-12">
                                <div class="single-item  wow animated fadeInLeft">
                                    <h6 class="font-h6">GROUP RELEASES A SATATEMENT </h6>
                                </div>
                            </div>
                            <div class="col-xs-12">
                                <div class="single-item-date">
                                    <h6 class="font-h6">MAY 16, 2023</h6>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xs-12" style="margin-top: 40px;">

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xs-12">
                                <div class="single-item  wow animated fadeInLeft">
                                    <h6 class="font-h6">HLG ANNOUNCES THE PROPOSED</h6>
                                </div>
                            </div>
                            <div class="col-xs-12">
                                <div class="single-item  wow animated fadeInLeft">
                                    <h6 class="font-h6">SALE OF RANDALL TO KANT & RIDER</h6>
                                </div>
                            </div>
                            <div class="col-xs-12">
                                <div class="single-item-date">
                                    <h6 class="font-h6">JULY 7, 2023</h6>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-xs-12 see-more">
                                <button class="btn btn-light">See More News</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

        </div>
        <div class="section-seven">
            <div class="bg__seven">
            </div>
        </div>

        <div class="section-contact-us" id="contact">
            <div class="container">
                <div class="row">
                    <div class="col-md-9 col-md-offset-2 col-xs-12">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <h2 class="font-h2">Contact Us</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-xs-12">
                                <h5>For any inquiries,<br /> please call or email us:</h5>
                                <p>214-310-6600 | sales@ho2properties.com</p>
                            </div>
                            <div class="col-md-12 col-xs-12">
                                <h5>our mailing address</h5>
                                <p>P.O. Box 664<br />
                                    Prosper, TX 75078<br />
                                    </p>
                            </div>
                            <!-- <div class="col-md-12 col-xs-12">
                                        <h5>Alternatively </h5>
                                        <p>you can fill in the following contact:</p>
                                    </div> -->
                        </div>
                        <!-- <form action="#" method="get">
                                    <div class="row">
                                        <div class="col-xs-12">
                                            <label class="form-label" for="first_name">&nbsp;</label>
                                            <input type="text" class="custom-input form-control" id="first_name"
                                                name="first_name" value="" placeholder="First Name">
                                        </div>
                                        <div class="col-xs-12">
                                            <label class="form-label" for="last_name">&nbsp;</label>
                                            <input type="text" class="custom-input form-control" id="last_name"
                                                name="last_name" value="" placeholder="Last Name">
                                        </div>
                                        <div class="col-xs-12">
                                            <label class="form-label" for="company">&nbsp;</label>
                                            <input type="text" class="custom-input form-control" id="company" name="company"
                                                value="" placeholder="Company">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <input type="submit" value="Submit" class="btn btn-lg btn-success submit-btn" />
                                        </div>
                                    </div>
                                </form> -->
                    </div>
                </div>
            </div>
        </div>
        <footer id="footer-section">
            <div class="container">
                <div class="row">
                    <div
                        class="col-md-9 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12 hidden-xs visible-sm visible-md visible-lg">
                        <div class="row footer-address">
                            <!-- <div class="col-sm-7 col-md-8">
                                        <p>500 Terry Francois Street, San Francisco, CA 94158</p>
                                        <p>info@mysite.com | 123-456-7890</p>
                                    </div> -->
                            <!-- <div class="col-sm-5 col-md-4">
                                        <div class="social-icons">
                                            <ul>
                                                <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                                <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                                <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                            </ul>
                                        </div>
    
                                    </div> -->
                        </div>
                        <div class="row footer-address-bottom">
                            <div class="col-sm-7 col-md-8">
                                <p>© 2022 - Ho2 Properties LLC. All rights reserved.</p>
                            </div>
                            <div class="col-sm-5 col-md-4">
                                <div class="third-arrow">
                                    <a href="#" class="scroll-link btn" data-id="top"><i class="fa fa-angle-up"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- show for extra small only-->
                    <div
                        class="col-md-9 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12 visible-xs hidden-sm hidden-md hidden-lg">
                        <div class="row footer-address">
                            <div class="col-xs-2">
                                <div class="third-arrow">
                                    <a href="#" class="scroll-link btn" data-id="top"><i class="fa fa-angle-up"></i></a>
                                </div>
                            </div>
                            <div class="col-xs-10">
                                <div class="social-icons">
                                    <ul>
                                        <!-- <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-linkedin"></i></a></li> -->
                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div class="row footer-address-bottom">
                            <div class="col-xs-12">
                                <!-- <p>P.O. Box 664, Prosper, TX 75078</p> -->
                                <p>sales@ho2properties.com | 214-310-6600</p>
                            </div>
                            <div class="col-xs12 text-center text-secoundry footer-copyright">
                                <p>&#169;{{currentYear}} - Ho2 Properties LLC. All rights reserved.</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </footer>
    </div>
    <script>
        // Wow
        new WOW({
            animateClass: 'animated',

            offset: 100
        }).init();
    </script>